<script>
import { SuawDivider, SuawInputGroup, SuawListSection, SuawSectionHeader, SuawArticleCard } from "@suaw/suaw-component-library";
import * as db from "../data.js";
import { formatDistanceToNow } from "date-fns";

export default {
  name: "AuthorPosts",
  components: {
    SuawDivider,
    SuawInputGroup,
    SuawListSection,
    SuawSectionHeader,
    SuawArticleCard
  },
  props: {
    authorId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      authors: db.authors(),
      articlesAll: db.articles()
    };
  },
  computed: {
    author() {
      return this.authors.filter(a => a.id === this.authorId)[0];
    },
    articles() {
      const currentRoute = this.$route.path;
      // Filter articles to exclude the current article
      const filteredArticles = this.articlesAll.filter(article => this.author.articles.includes(article.id)).filter(article => article.route !== currentRoute);
      return filteredArticles;
    }
  },
  methods: {
    getRelativeTime(posted) {
      const result = formatDistanceToNow(new Date(posted), { addSuffix: true });
      return result;
    }
  }
};
</script>

<template>
  <SuawInputGroup v-if="articles.length > 0" direction="column" group-gap="triple">
    <SuawDivider type="inner" color="medium" />
    <SuawSectionHeader :header-label="`More Posts by ${author.name}`" :show-button="false" />
    <SuawListSection list-direction="horizontal">
      <SuawArticleCard
        v-for="article in articles"
        :id="article.id"
        :key="article.id"
        :author-name="article.author"
        author-avatar-url=""
        author-avatar-color="blue"
        :author-avatar-initials="author.initials"
        :is-author-avatar-hidden="false"
        :thumbnail="article.thumbnail"
        :category="article.category"
        :heading="article.heading"
        :description="article.preview"
        :published="getRelativeTime(article.posted)"
        :route="article.route"
      />
    </SuawListSection>
  </SuawInputGroup>
</template>
